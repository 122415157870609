<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">动物检疫</h3>
    <div class="detail">
      <h4 class="title">实验动物基本情况</h4>
      <div class="desc">
        <span>动物品系：{{ activeInfo.strain }}</span>
        <span>入驻时间：{{ inTime| formatDay }}</span>
        <span v-if="activeInfo.isGene==1">基因类型：{{ activeInfo.geneKeys }}</span>
      </div>
      <el-form ref="addForm" size="small" :model="animal" class="addForm" label-suffix=":" :disabled="disabled"
               label-width="80px">
        <h3 class="tit">实验动物检疫结果</h3>
        <span class="qualified">合格动物：</span>
        <el-table
            :data="animal.qualified" border
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
        >
          <el-table-column label="基因类型" v-if="activeInfo.isGene==1">
            <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
              <template slot-scope="scope">
                {{ scope.row.typeList[ind].type }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
              label="性别" width="60"
              show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column label="耳号" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="耳号" v-model="scope.row.overbit"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="笼架编号" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="笼架编号" v-model="scope.row.frameNo"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="笼舍编号" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="笼舍编号" v-model="scope.row.cageNo"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="出生日期">
            <template slot-scope="scope">
              <el-date-picker
                  :picker-options="pickerOptions"
                  size="small"
                  v-model="scope.row.birthDate"
                  type="date"
                  placeholder="出生日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <!--          <el-table-column label="数量">
                      <template slot-scope="scope">
                        <el-input size="small" type="number" :min="0" placeholder="数量" :max="scope.row.maxCount"
                                  v-model="scope.row.count"></el-input>
                      </template>
                    </el-table-column>-->
          <el-table-column label="观察状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="观察状态" v-model="scope.row.symptom"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="editColor">
                <el-button type="text" @click="copy(scope.row,scope.$index,0)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.$index,0)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item label="处理方式" prop="quaHandle">
          <el-input v-model="animal.quaHandle" clearable placeholder="请输入处理方式">
          </el-input>
        </el-form-item>
        <span class="qualified">不合格动物：</span>
        <el-table
            :data="animal.unqualified" border
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            style="width: calc(100% - 12px);font-size:14px;color:#333">
          <el-table-column label="基因类型" v-if="activeInfo.isGene==1">
            <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
              <template slot-scope="scope">
                {{ scope.row.typeList[ind].type }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
              label="性别" width="60"
              show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column label="耳号" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="耳号" v-model="scope.row.overbit"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="笼架编号" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="笼架编号" v-model="scope.row.frameNo"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="笼舍编号" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="笼舍编号" v-model="scope.row.cageNo"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="出生日期">
            <template slot-scope="scope">
              <el-date-picker
                  :picker-options="pickerOptions"
                  size="small"
                  v-model="scope.row.birthDate"
                  type="date"
                  placeholder="出生日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <!--          <el-table-column label="数量">
                      <template slot-scope="scope">
                        <el-input size="small" placeholder="数量" type="number" :min="0" :max="scope.row.maxCount"
                                  v-model="scope.row.count"></el-input>
                      </template>
                    </el-table-column>-->
          <el-table-column label="观察状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input size="small" placeholder="观察状态" v-model="scope.row.symptom"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="editColor">
                <el-button type="text" @click="copy(scope.row,scope.$index,1)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.$index,1)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item label="处理方式" prop="unquaHandle">
          <el-input v-model="animal.unquaHandle" clearable placeholder="请输入处理方式">
          </el-input>
        </el-form-item>
        <h3 class="tit">指定兽医</h3>
        <el-form-item label="兽医指定">
          <el-radio-group v-model="animal.reviewerName" class="radioGroup">
            <el-radio v-for="item in userList" :label="item.nickname" :key="item.userId"
                      @click.native="getAppId(item.userId,item.nickname)"
            > {{ item.nickname }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="fromSave">
        <template v-if="status==10 || status==11 || status ==14">
          <el-button type="primary" size="small" :disabled="disabled" @click="submitBtn('addForm',1)">检疫完成</el-button>
          <el-button plain type="primary" size="small" :disabled="disabled" @click="submitBtn('addForm',0)">保存
          </el-button>
        </template>
        <el-button plain type="primary" size="small" @click="exportInfo()">导出预览</el-button>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import moment from 'moment'

export default {
  name: "quarantine",
  props: ["id", "status"],
  data() {
    return {
      activeInfo: {
        aniRecept: {}
      },
      typeList: [],
      animal: {
        // 合格
        qualified: [],
        quaHandle: "",
        // 不合格
        unqualified: [],
        unquaHandle: "",
        reviewerId: null,
        reviewerName: ''
      },
      userList: [],
      disabled: false,
      readonly: false,
      quarState: null,
      quarId: null,
      // 出生日期处理
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      inTime: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  mounted() {
    if (this.status == 10) {
      this.getType()
    } else {
      this.getQuarantine()
    }
    // 获取成员
    this.$get("/oauth/user/personal/" + this.user.companyId).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
  },
  methods: {
    getType() {
      let that = this;
      that.$get("/subject/order/to/" + this.id).then(res => {
        if (res.status === 200) {
          that.activeInfo = res.data
          this.inTime = res.data.aniRecept.receptTime
          // 数组赋值
          res.data.aniOrderDetailList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
            let a = {
              orderDetailId: obj.id,
              typeList: obj.typeList,
              gender: obj.gender,
              gene: obj.gene,
              overbit: "",
              frameNo: "",
              cageNo: "",
              birthDate: "",
              // maxCount: obj.count,
              // count: "",
              symptom: "",
            }
            this.animal.qualified.push(this.deepClone4(a))

            this.animal.unqualified.push(this.deepClone4(a))
          })
          this.getTypeList()
        }
      })
    },
    // 获取检疫详情
    getQuarantine() {
      this.$get("/subject/quar/" + this.id).then(res => {
        if (res.status == 200) {
          // 检疫状态
          this.quarState = res.data.aniOrder.aniQuar.state
          // 检疫id
          this.quarId = res.data.aniOrder.aniQuar.id

          if (this.quarState == 1 || this.quarState == 2) {
            this.readonly = true
            this.disabled = true
          }
          this.activeInfo = res.data.aniOrder
          this.inTime = res.data.aniOrder.inTime

          // 处理方式赋值
          this.animal.quaHandle = res.data.aniOrder.aniQuar.quaHandle

          this.animal.unquaHandle = res.data.aniOrder.aniQuar.unquaHandle

          // 赋值兽医
          this.animal.reviewerId = res.data.aniOrder.aniQuar.reviewerId
          this.animal.reviewerName = res.data.aniOrder.aniQuar.reviewerName


          res.data.aniOrder.quarResultList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
            // 筛选合格不合格
            if (obj.isQualify == 0) {
              this.animal.unqualified.push(this.deepClone4(obj))
            } else {
              this.animal.qualified.push(this.deepClone4(obj))
            }
          })
          this.getTypeList()
        }
      })
    },
    // 获取基因类型
    getTypeList() {
      // 赋值typeList
      let arr = this.activeInfo.geneKeys.split(',')
      arr.forEach(item => {
        this.typeList.push({
          title: item
        })
      })
    },
    deepClone4(target, map = new Map()) {
      // 检测数据类型
      if (typeof target === 'object' && target !== null) {
        // 克隆数据之前，判断之前是否克隆过
        let cache = map.get(target);
        if (cache) {
          return cache;
        }
        // 创建一个容器
        const isArray = Array.isArray(target)
        const result = isArray ? [] : {};
        // 将新结果存入到容器中
        map.set(target, result);
        // 正则判断
        if (target.constructor === RegExp) {
          return target;
        } else if (isArray) {
          // 遍历数组
          target.forEach((item, index) => {
            result[index] = this.deepClone4(item, map);
          });
        } else {// 对象
          Object.keys(target).forEach(key => {
            result[key] = this.deepClone4(target[key], map);
          });
        }
        return result;
      } else {
        return target;
      }
    },
    // 复制
    copy(val, index, ind) {
      if (ind == 0) {
        this.animal.qualified.splice(index, 0, JSON.parse(JSON.stringify(val)))
      } else {
        this.animal.unqualified.splice(index, 0, JSON.parse(JSON.stringify(val)))
      }
    },
    // 删除
    deleteA(index, ind) {
      if (ind == 0) {
        this.animal.qualified.splice(index, 1)
      } else {
        this.animal.unqualified.splice(index, 1)
      }
    },
    // 指定兽医
    getAppId(id, name) {
      this.animal.reviewerId = id
      this.animal.reviewerName = name
    },
    // 提交检疫
    submitBtn(task, state) {
      this.$refs[task].validate((valid) => {
        if (valid) {
          let result = true
          let a = [], a1 = [], a2 = [], a3 = [];
          this.animal.qualified.forEach((item, index) => {

            let no = index + 1
            if (item.overbit == '') {
              result = result && false
              a.push('第' + no + '行：耳号不能为空')
              // this.$message.warning('第' + no + '行：耳号不能为空')
            }
            if (item.frameNo == '') {
              result = result && false
              a1.push('第' + no + '行：笼架编号不能为空')
            }
            if (item.cageNo == '') {
              result = result && false
              a2.push('第' + no + '行：笼舍编号不能为空')
            }
            if (item.birthDate == '') {
              result = result && false
              a3.push('第' + no + '行：出生日期不能为空')
            }
            /*if (item.count == '') {
              result = result && false
              a3.push('第' + no + '行：数量不能为空')
            }*/
            item.isQualify = 1
            item.birthDate = moment(item.birthDate).format("yyyy-MM-DD HH:mm:ss")
          })
          if (!result) {
            if (a && a.length > 0) {
              return this.$message({message: "有" + a.length + '行耳号为空', type: 'warning'})
            }
            if (a1 && a1.length > 0) {
              return this.$message({message: "有" + a1.length + '行笼架编号为空', type: 'warning'})
            }
            if (a2 && a2.length > 0) {
              return this.$message({message: "有" + a2.length + '行笼舍编号为空', type: 'warning'})
            }
            if (a3 && a3.length > 0) {
              return this.$message({message: "有" + a3.length + '出生日期为空', type: 'warning'})
            }
          }

          this.animal.unqualified.forEach(item => {
            item.isQualify = 0
            if (item.birthDate) {
              item.birthDate = moment(item.birthDate).format("yyyy-MM-DD HH:mm:ss")
            }
            /* if (item.count == '') {
               item.count = 0
             }*/
          })
          if (this.animal.reviewerId == null) {
            this.$message.warning("请选择指定兽医")
            return;
          }
          let arr = this.animal.qualified.concat(this.animal.unqualified)
          let data = {
            state: state,
            unquaHandle: this.animal.unquaHandle,
            quaHandle: this.animal.quaHandle,
            quarResultList: arr,
            subId: this.activeInfo.subId,
            reviewerId: this.animal.reviewerId,
            reviewerName: this.animal.reviewerName,
            inTimeStr: moment(this.inTime).format("yyyy-MM-DD HH:mm:ss")
          }
          let url;
          if (this.quarState == null) {
            url = this.$postJson('/ani/quar', data)
          } else {
            this.$set(data, "id", this.quarId)
            url = this.$putJson('/ani/quar', data)
          }
          url.then(res => {
            this.$message.success("提交成功")
            this.readonly = true
            this.disabled = true
            this.$router.go(-1)
          }).catch(err => {
            this.$message.error("检疫失败")
          })
        }
      })
    },
    exportInfo() {
      this.$emit('changeStatus', false, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
